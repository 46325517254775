<template>

   <div>
       <div class="leisure_button_group_sub">
           <router-link tag="button" class="bw2"
                        :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_HILOW_5S}"
                        :to="{path:'/tgame365/tgame365highlow5s', query: {t: new Date().getTime()}}">
               하이로우 5초
           </router-link>
<!--           <router-link tag="button" class="bw3"-->
<!--                        :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_HILOW}"-->
<!--                        :to="{path:'/tgame365/tgame365highlow', query: {t: new Date().getTime()}}">-->
<!--               하이로우 10초-->
<!--           </router-link>-->
<!--           <router-link tag="button" class="bw3 mt-1"-->
<!--                        :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_BACCARAT}"-->
<!--                        :to="{path:'/tgame365/tgame365baccarat', query: {t: new Date().getTime()}}">-->
<!--               바카라-->
<!--           </router-link>-->
           <router-link tag="button" class="bw2"
                        :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_RUOLLET}"
                        :to="{path:'/tgame365/tgame365ruollet', query: {t: new Date().getTime()}}">
               룰렛
           </router-link>
<!--           <router-link tag="button" class="bw3 mt-1"-->
<!--                        :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_FW}"-->
<!--                        :to="{path:'/tgame365/tgame365fw', query: {t: new Date().getTime()}}">-->
<!--               포츈힐-->
<!--           </router-link>-->
       </div>

   </div>

</template>
<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureCompTgames365GameLinks",
        props: {
            comp: {
                type: Number,
                default() {
                    return -1
                }
            },
        },
        data() {
            return {
                leisureConst: leisureConst,
            }
        }
    }
</script>

<style scoped>
.mt-1{
    margin-top: 10px;
}
</style>