<template>
    <div>
        <div class="leisure_buttons_group"
             v-if=" $store.state.currentLeisureGameComp != leisureConst.LEISURE_COMP_BET365">
            <router-link tag="button" class="bw2 btn_minigame_comp"
                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_CROWN}"
                         :to="{path:'/leisure/sutda', query: {t: new Date().getTime()}}">
                크라운
            </router-link>
<!--            <router-link tag="button" class="bw3 btn_minigame_comp"-->
<!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_SKY}"-->
<!--                         :to="{path:'/leisure/sky_speed_bakara', query: {t: new Date().getTime()}}">-->
<!--                스카이파크-->
<!--            </router-link>-->
            <router-link tag="button" class="bw2 btn_minigame_comp"
                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_LOTUS}"
                         :to="{path:'/leisure/lotus_bakara', query: {t: new Date().getTime()}}">
                로투스
            </router-link>
        </div>
        <div class="leisure_buttons_group"
             v-if=" $store.state.currentLeisureGameComp != leisureConst.LEISURE_COMP_BET365">
            <router-link tag="button" class="bw2 btn_minigame_comp"
                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_EOS}"
                         :to="{path:'/leisure/eospowerball1m', query: {t: new Date().getTime()}}">
                엔트리
            </router-link>

            <router-link tag="button" class="bw2 btn_minigame_comp "
                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_STAR_SADALI}"
                         :to="{path:'/leisure/ssd1', query: {t: new Date().getTime()}}">
                보스코어
            </router-link>
        </div>
    </div>


</template>
<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureGameCompLinks",
        data() {
            return {
                leisureConst: leisureConst,
            }
        },
        methods: {
            commingsoon() {
                alert('준비중입니다')
            },
        }
    }
</script>

<style scoped>

</style>